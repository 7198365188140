import React from 'react';
import { graphql, PageProps } from 'gatsby';
import BlockContent from '@sanity/block-content-to-react';
import { IGatsbyImageData, getSrc } from 'gatsby-plugin-image';
// eslint-disable-next-line no-unused-vars
import tw from 'twin.macro';

import useCompanyInfo from '../hooks/use-company-info';
import PageTemplate from '../components/page-template';
import Hero from '../components/hero';
import SEO from '../components/seo';
import Card from '../components/card';
import Callout from '../components/callout';
import HoursBlock from '../components/hours-block';
import StaticMapFluid from '../components/static-map-fluid';
import {
  FlexContainer,
  PageSection,
  TextContainer,
  PageTitle,
} from '../components/ui-elements';

const CardContainer = tw(PageSection)`w-full bg-primary-200 md:pb-0 md:px-4`;

type PageCard = {
  _key: string;
  title: string;
  _rawBody: string;
  pageLink?: string;
  image: {
    alt: string;
    caption?: string;
    asset: IGatsbyImageData;
  };
};

type GraphQLData = {
  page: {
    _rawBody: string;
    calloutTitle: string;
    seo: {
      title: string;
      description: string;
      keywords: string[];
    };
    headerImage: {
      asset: IGatsbyImageData;
    };
    pageCards: PageCard[];
  };
  staticMap: {
    childFile: {
      childImageSharp: IGatsbyImageData;
    };
  };
};

type IndexPageProps = PageProps<GraphQLData>;

const IndexPage = (props: IndexPageProps) => {
  const { data } = props;
  const {
    page: {
      calloutTitle,
      _rawBody,
      seo: { title, description, keywords },
      headerImage,
      pageCards,
    },
    staticMap,
  } = data;
  const { hours, mapMeta } = useCompanyInfo();
  const headerImageSrc = getSrc(headerImage.asset) || '';
  console.log(staticMap.childFile.childImageSharp);

  return (
    <PageTemplate>
      <SEO title={title} description={description} keywords={keywords} />
      <Hero image={headerImageSrc} />
      <Callout title={calloutTitle} />
      <PageSection tw="md:mt-8">
        <FlexContainer>
          <div tw="md:w-1/2">
            <PageTitle>{title}</PageTitle>
            <TextContainer tw="md:leading-relaxed">
              <BlockContent blocks={_rawBody} />
            </TextContainer>
          </div>
          <div tw="md:w-1/3">
            <HoursBlock hours={hours} tw="shadow" />
            <StaticMapFluid
              fluid={staticMap.childFile.childImageSharp}
              located={mapMeta.mapText}
              mapUrl={mapMeta.mapUrl}
            />
          </div>
        </FlexContainer>
      </PageSection>
      <CardContainer>
        <FlexContainer tw="w-full">
          {pageCards &&
            pageCards.map((item) => (
              <Card
                title={item.title}
                cardImageFluid={item.image.asset}
                alt={item.image.alt}
                key={item._key}
                body={item._rawBody}
                url={item.pageLink}
              />
            ))}
        </FlexContainer>
      </CardContainer>
    </PageTemplate>
  );
};

export default IndexPage;

export const query = graphql`
  query HomePageQuery {
    page: sanityHomePage {
      calloutTitle
      seo {
        title
        keywords
        description
      }
      _rawBody(resolveReferences: { maxDepth: 10 })
      headerImage {
        asset {
          gatsbyImageData(width: 1600, placeholder: BLURRED)
        }
      }
      pageCards {
        _key
        title
        _rawBody
        pageLink
        image {
          alt
          caption
          asset {
            gatsbyImageData(width: 375, placeholder: BLURRED)
          }
        }
      }
    }
    staticMap(nickname: { eq: "homePage" }) {
      childFile {
        childImageSharp {
          gatsbyImageData(width: 350, layout: CONSTRAINED)
        }
      }
    }
  }
`;
