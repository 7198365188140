import React from 'react';
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import tw, { styled } from 'twin.macro';
import BlockContent from '@sanity/block-content-to-react';

const CardWrapper = styled.div`
  ${tw`w-full flex flex-col flex-nowrap rounded-lg md:mx-4 md:w-1/3 border-4 border-solid border-white mb-4`}
  background-color: #fff;
  min-width: 300px;
`;

type ImageProps = {
  maxHeight: string;
};

const CardImageWrapper = styled.div<ImageProps>`
  max-height: ${(props) => (props.maxHeight ? props.maxHeight : '375px')};
  overflow: hidden;
  border-top-left-radius: 0.2rem;
  border-top-right-radius: 0.2rem;
`;
const CardCaptionWrapper = styled.div`
  ${tw`font-heading font-light bg-primary-500 text-center text-primary-100 p-3 uppercase text-xl`}
  color: rgba(235, 224, 213, 1);
  opacity: 90%;
  width: 100%;
`;
const CardCaptionTitle = styled.span`
  color: rgba(235, 224, 213, 1);
`;
const CardBody = styled.div`
  ${tw`font-sans text-base leading-relaxed text-lg px-3 py-4 text-gray-800`}
  & > ul, & > div > ul {
    ${tw`list-disc list-inside`}
  }
`;
const LinkContainer = styled.div`
  ${tw`text-center w-full pb-6 pt-4 mt-auto`}
`;

interface ComponentProps {
  title: string;
  alt: string;
  body: React.ReactNode;
  cardImageFluid: IGatsbyImageData;
  linkTitle?: string;
  maxHeight?: string;
  url?: string;
}

const Card = ({
  title,
  alt,
  body,
  cardImageFluid,
  linkTitle = 'Learn More',
  maxHeight = '375px',
  url = '',
}: ComponentProps) => {
  const external = url && /http/.test(url);
  const cardImage = getImage(cardImageFluid);

  return (
    <CardWrapper>
      {cardImage && (
        <CardImageWrapper maxHeight={maxHeight}>
          <GatsbyImage image={cardImage} alt={alt} tw="w-full" />
        </CardImageWrapper>
      )}
      <CardCaptionWrapper>
        <CardCaptionTitle>{title}</CardCaptionTitle>
      </CardCaptionWrapper>
      <CardBody>
        <BlockContent blocks={body} />
      </CardBody>
      <LinkContainer>
        {url && !external && (
          <Link to={url} tw="underline uppercase text-lg text-center">
            {linkTitle}
          </Link>
        )}
      </LinkContainer>
    </CardWrapper>
  );
};

export default Card;
