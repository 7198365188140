import React from 'react';
import tw, { styled } from 'twin.macro';

import useCompanyInfo from '../hooks/use-company-info';

const CalloutContainer = styled.div`
  ${tw`text-center py-4 py-4 shadow-lg bg-primary-300`}
`;
const CalloutTitle = styled.p`
  ${tw`leading-tight text-base px-1 mb-0 text-gray-100 uppercase md:text-2xl `}
`;
const Address = styled.p`
  ${tw`leading-tight text-sm py-1 md:text-base text-gray-300 mb-0 uppercase`}
`;
const Phone = styled.a`
  ${tw`text-xl pt-4 md:text-2xl text-gray-100 uppercase m-0`}
`;

const Callout = (props: { title: string }) => {
  const {
    address: { address1, city, state, zipCode },
    phoneNum,
  } = useCompanyInfo();
  const { title } = props;

  return (
    <CalloutContainer>
      <CalloutTitle>{title}</CalloutTitle>
      <Address>
        {address1}, {city}, {state} {zipCode}
      </Address>
      <Phone href={`tel:${phoneNum}`}>{phoneNum}</Phone>
    </CalloutContainer>
  );
};

export default Callout;
