import React from 'react';
import tw, { styled } from 'twin.macro';

interface StyledWrapProps {
  image: string;
}

const HeroWrapper = styled.section<StyledWrapProps>`
  color: white;
  font-size: 3rem;
  background-size: cover;
  background: url(${(props) => props.image}) bottom;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  align-items: center;
  position: relative;
  height: 300px;
  @media (min-width: 768px) {
    height: 425px;
    margin-top: 112px;
  }
  margin-top: 80px;
`;

const HeroTextWrapper = styled.figcaption`
  position: absolute;
  bottom: 0;
  background-color: rgba(62, 57, 56, 0.9);
  width: 60%;
  margin-left: 20%;
  text-align: center;
`;

const HeroText = styled.section`
  ${tw`text-xl uppercase py-2`};
  color: rgba(235, 224, 213, 1);
`;

type HeroProps = {
  image: string;
  text?: string;
};

const Hero = ({ image, text = '' }: HeroProps) => (
  <HeroWrapper image={image}>
    <HeroTextWrapper>{text && <HeroText>{text}</HeroText>}</HeroTextWrapper>
  </HeroWrapper>
);

export default Hero;
